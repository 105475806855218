<template>
  <div
    v-auto-animate="{ duration: 80 }"
    class="relative"
  >
    <div
      class="flex items-center space-x-2 cursor-pointer"
      @click="isLangMenuOpen = true"
    >
      <span
        class="uppercase"
      >
        {{ getLocaleCode() }}
      </span>
      <IconLoader
        name="filled_arrow_down"
        class="h-1"
      />
    </div>
    <div
      v-if="isLangMenuOpen"
      v-on-click-outside="onClickAway"
      class="flex flex-col absolute z-10 right-0 w-[172px] bg-white rounded-xl shadow-dropdown-list px-2 py-3 space-y-1"
    >
      <a
        v-for="locale in availableLocales"
        :key="locale"
        class="rounded-lg h-10 flex items-center justify-between px-3 hover:bg-07-Grey-200"
        :class="{ 'bg-07-Grey-200': getLocaleCode() === locale }"
        @click="switchLanguage(locale as 'keys' | 'en' | 'de' | 'it')"
      >
        <span>
          {{ getLocaleName(locale as 'keys' | 'en' | 'de' | 'it') }}
        </span>
        <IconLoader
          v-if="locale === getLocaleCode()"
          v-auto-animate="{ duration: 80 }"
          name="checkmark_agency"
          class="h-3"
        />
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'
import type { LocaleObject } from '@nuxtjs/i18n'

const { $i18n: i18n } = useNuxtApp()
const config = useRuntimeConfig()

const availableLocales = computed((): Array<string> => {
  if (config.public.vercelEnv !== 'production') {
    return i18n.availableLocales
  }
  return i18n.availableLocales.filter((locale: 'keys' | 'en' | 'de' | 'it') => locale !== 'keys')
})

const getLocaleName = (locale: 'keys' | 'en' | 'de' | 'it'): string | undefined => {
  const innerLocales: Array<LocaleObject> = i18n.locales.value
  return innerLocales.find((innerLocale: LocaleObject) => innerLocale.code === locale)?.name
}
const getLocaleCode = (): string => i18n.locale.value

const switchLanguage = (locale: 'keys' | 'en' | 'de' | 'it'): void => {
  void i18n.setLocale(locale)
  onClickAway()
}

const isLangMenuOpen = ref<boolean>(false)
const onClickAway = (): void => {
  isLangMenuOpen.value = false
}
</script>

<style lang="scss">
.router-link-exact-active {
  @apply bg-07-Grey-200
}
</style>
